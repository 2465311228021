import { callApi, createAuthHeaderEcomerce } from "./Request";
import random from "alphanumeric";
import { getProductByKey } from "./Product";

const auth_url = process.env.REACT_APP_BASIC_AUTH_ECOMERCE_TOOLS;
const ecommerceCart = `${process.env.REACT_APP_BASIC_ECOMERCE_URL}/carts`;
const random_code = random(20);
const countryData = {
  México: {
    code:"MX",
    currency:"MXN",
    finalPrice: process.env.REACT_APP_NEW_CHECKOUT_FINAL_PRICE_MX,
    locale: "es-MX"
  },
  Colombia: {
    code:"CO",
    currency:"COP",
    finalPrice: process.env.REACT_APP_NEW_CHECKOUT_FINAL_PRICE_CO,
    locale: "es-CO"
  },
  Peru: {
    code:"PE",
    currency:"PEN",
    finalPrice: process.env.REACT_APP_NEW_CHECKOUT_FINAL_PRICE_PE,
    locale: "es-PE"
  },
  default: {
    code:"MX",
    currency:"MXN",
    finalPrice: process.env.REACT_APP_NEW_CHECKOUT_FINAL_PRICE_MX,
    locale: "es-MX"
  }
};

export const validateCustomerMigration = async (customerId) => {
  const {
    REACT_APP_VALIDATE_CUSTOMER,
    REACT_APP_VALIDATE_CUSTOMER_USER: customerUser,
    REACT_APP_VALIDATE_CUSTOMER_PASS: customerPass,
  } = process.env;

  const user = customerUser;
  const password = customerPass;
  const auth = `${user}:${password}`;
  const authEncoded = `Basic ${Buffer.from(auth).toString("base64")}`;

  try {
    const apiResponse = await fetch(
      `${REACT_APP_VALIDATE_CUSTOMER}/${customerId}`,
      {
        method: "POST",
        headers: {
          Authorization: authEncoded,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          getCart: true,
        }),
      }
    );

    const data = await apiResponse.json();
    return data;
  } catch {
    return false;
  }
};

export const auth = (success, error) =>
  callApi(
    auth_url,
    {
      headers: createAuthHeaderEcomerce(),
      method: "POST",
    },
    success,
    error
  );

export const new_car = async (token, success, error, patient, branch) => {
  
  const responseValidate = await validateCustomerMigration(patient.CustomerId);
  const sku = branch?.Center_Type === 'Liverpool' ? 'liverpool_cita_de_escaneo' : 'appointment'
  const body = {
    currency: countryData[patient.Country_Ops].currency,
    key: `cart-key-${random_code}`,
    customerEmail: patient.Email,
    customerId: responseValidate.ctCustomerId,
    shippingAddress: {
      country: countryData[patient.Country_Ops].code,
    },
    country: countryData[patient.Country_Ops].code,
    lineItems: [
      {
        quantity: 1,
        sku: sku,
      },
    ],
    origin: "Customer",
    inventoryMode: "None",
  };

  if (responseValidate.activeCart) {
    try {
      const currentCartJson = await fetch(
        `${ecommerceCart}/${responseValidate.activeCart.id}`,
        {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
            "Content-Type": "application/json",
          },
        }
      );

      const currentCart = await currentCartJson.json();

      if (currentCart) {
        const lineItems = currentCart.lineItems;
        const customLineItems = currentCart.customLineItems;
        const items = [...lineItems, ...customLineItems];

        const updateActions = {
          actions: []
        }

        updateActions.actions = items.map((item) => {
          if (!item.lineItemMode) {
            return {
              action: "removeCustomLineItem",
              customLineItemId: item.id
            };
          } else {
            return {
              action: "removeLineItem",
              lineItemId: item.id,
              quantity: item.quantity,
            };
          }
        });

        updateActions.actions.push({
          action: "addLineItem",
          sku: sku,
          quantity: 1,
        });

        await callApi(
          `${ecommerceCart}/${responseValidate.activeCart.id}`,
          {
            headers: {
              "x-api-key": process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
            },
            method: "PUT",
            body: JSON.stringify(updateActions),
          },
          success,
          error
        );
      }
    } catch (error) {
      console.log(error, "No se pudo actualizar el carrito existente");
    }
  } else {
    await callApi(
      ecommerceCart,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
        },
        method: "POST",
        body: JSON.stringify(body),
      },
      success,
      error
    );
  }
};
