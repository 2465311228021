import { callApiDirectly } from './Request'
import addUtm from '../Utils/addUtm'

const apiAcuity = {

  /**
   * Appointment Types
   * @returns {void} .
   */
  appointmentTypes() {
    return callApiDirectly(
      `${process.env.REACT_APP_ACUITY_API_GATEWAY}appointment-types`,
      {
        method: 'GET',
      }
    )
  },

  /**
   * Calendars
   * @returns {void} .
   */
  calendars() {
    return callApiDirectly(`${process.env.REACT_APP_ACUITY_API_GATEWAY}calendars`, {
      method: 'GET',
    })
  },

  /**
   * Dates
   * @param {String} typeId .
   * @param {String} month .
   * @param {String} calendarId .
   * @returns {void} .
   */
  dates(typeId, month, calendarId) {
    return callApiDirectly(
      `${process.env.REACT_APP_ACUITY_API_GATEWAY
      }dates/${typeId}/${month}/${calendarId}`,
      {
        method: 'GET',
      }
    )
  },

  /**
   * Times
   * @param {String} typeId .
   * @param {String} date .
   * @param {String} calendarId .
   * @returns {void} .
   */
  times(typeId, date, calendarId) {
    return callApiDirectly(
      `${process.env.REACT_APP_ACUITY_API_GATEWAY
      }times/${typeId}/${date}/${calendarId}`,
      {
        method: 'GET',
      }
    )
  },

  /**
   * Update Appointment
   * @param {String} id .
   * @returns {void} .
   */
  update(id, body) {
    return callApiDirectly(
      `${process.env.REACT_APP_ACUITY_API_GATEWAY
      }appointment/update/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify(body)
      }
    )
  },

  /**
   * Cancel Appointment
   * @param {String} id .
   * @returns {void} .
   */
  cancel(id, reason, utmSource, utmTerm) {
    return callApiDirectly(
      `${process.env.REACT_APP_ACUITY_API_GATEWAY
      }appointment/cancel/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          form: reason,
          utmSource,
          utmTerm
        })
      }
    )
  },

  /**
   * Appointments
   * @param {String} datetime .
   * @param {String} appointmentTypeID .
   * @param {Object} userData .
   * @param {String} userData.firstName .
   * @param {String} userData.email .
   * @param {String} userData.phone .
   * @param {String} userData.lastName .
   * @param {String} customerId .
   * @param {String} publicKey .
   * @returns {void} .
   */
  appointments(
    datetime,
    appointmentTypeID,
    userData,
    customerId,
    publicKey,
    partner,
    journey,
    payment,
    reason,
    calendarId
  ) {
    if (!partner) {
      partner = 'retail'
    }

    if (!journey) {
      journey = 'regular'
    }

    const fields = addUtm([
      {
        id: process.env.REACT_APP_CUSTOMERID_FIELD_ID,
        value: customerId
      },
      {
        id: process.env.REACT_APP_APPOINTMENT_FIELD_ID,
        value: process.env.REACT_APP_APPOINTMENT_FIELD
      },
      {
        id: process.env.REACT_APP_PUBLICKEY_FIELD_ID,
        value: publicKey
      },
      {
        id: process.env.REACT_APP_JOURNEY_FIELD_ID,
        value: journey
      },
      {
        id: process.env.REACT_APP_PARTNER_FIELD_ID,
        value: partner
      },
      {
        id: process.env.REACT_APP_PAYMENT_FIELD_ID,
        value: payment
      }
    ])

    const dataAppointment = {
      datetime,
      appointmentTypeID,
      ...userData,
      form: reason,
      fields
    }
    if(calendarId){
      dataAppointment.calendarID = calendarId
    }
    console.log('dataAppointment-refactor', dataAppointment)
    return callApiDirectly(`${process.env.REACT_APP_ACUITY_API_GATEWAY}appointments`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(dataAppointment)
    })
  },
}

export default apiAcuity
