const messages = {
  back: 'cambiar fecha y hora',
  buttonText: 'CONFIRMAR CITA',
  confirmAppointment: 'CONFIRMAR CITA',
  confirmAppointmentFree: 'CONFIRMAR CITA GRATIS',
  payAppointment: 'PAGAR CITA',
  freeAppointment: 'AGENDAR CITA GRATIS',
  rescheduleAppointment: 'REAGENDAR CITA',
  error: 'Hubo un error al crear la cita, intenta más tarde',
  welcome: '{Name}, la sonrisa de tus sueños esta cerca',
  preloadSteps: [
    'Revisando fecha y horario',
    'Fecha y horario confirmada',
    'Agendando cita',
    'Cita agendada',
    'Programando recordatorios',
    'Recordatorios programados',
    'Generando página con los detalles de la cita'
  ]
}

export default messages
