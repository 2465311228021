import React from "react";
import "./CandidateModal.css";
import { informativeModalMessages } from "./messajes";

/**
 * InformativeModal Component
 * @returns {JSX.Element} InformativeModal Component
 */

const InformativeModalText = () => {
  const {
    modalTitle,
    modalSubTitle,
    modalDescriptionText1,
    modalPrice,
    modalDescriptionText2,
    AppointmentIncludeTitle,
    AppointmentIncludeStep1,
    AppointmentIncludeStep2,
    AppointmentIncludeStep3,
  } = informativeModalMessages.Peru;
  return (
    <>
      <h1 className="modal-title_informative">{modalTitle}</h1>
      <p className="modal-subtitle_informative">{modalSubTitle}</p>
      <p className="modal-description_informative">
        {modalDescriptionText1}
        <span>{modalPrice}</span> {modalDescriptionText2}
      </p>
      <div className="modal-appointment_container">
      <p className="modal-appointment_title">{AppointmentIncludeTitle}</p>
      <div className="modal-appointment_content">
        <span className="modal-appointment_circle"> </span>
        <p className="modal-appointment_step">{AppointmentIncludeStep1}</p>
      </div>
      <div className="modal-appointment_content">
        <span className="modal-appointment_circle"> </span>
        <p className="modal-appointment_step">{AppointmentIncludeStep2}</p>
      </div>
      <div className="modal-appointment_content">
        <span className="modal-appointment_circle"> </span>
        <p className="modal-appointment_step">{AppointmentIncludeStep3}</p>
      </div>

      </div>
      
    </>
  );
};

export default InformativeModalText;
